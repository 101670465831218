import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { referenceProjectsConfig } from '@/configs/referenceProjects';
import { stripHtmlTags } from '@/helpers/utils';

const Faq = dynamic(() => import('@/components/Faq/Faq'));
const BottomCTA = dynamic(() => import('@/components/BottomCTA/BottomCTA'));
const RichText = dynamic(() => import('@/components/RichText/RichText'));
const FaqTeam = dynamic(() => import('@/components/FaqTeam/FaqTeam'));
const HomepageHero = dynamic(() =>
  import('@/components/HomepageHero/HomepageHero')
);
const Promises = dynamic(() => import('@/components/Promises/Promises'));
const Hero = dynamic(() => import('@/components/CategoryHero/CategoryHero'));
const PartnersSection = dynamic(() =>
  import('@/components/PartnersSection/PartnersSection')
);
const PageMargins = dynamic(() =>
  import('@/components/ui/PageMargins/PageMargins')
);
const ProductCatalog = dynamic(() =>
  import('@/components/ui/ProductCatalog/ProductCatalog')
);
const CardGrid = dynamic(() => import('@/components/CardGrid/CardGrid'));
const Testimonials = dynamic(() =>
  import('@/components/ui/Testimonials/Testimonials')
);
const TextContent = dynamic(() =>
  import('@/components/ui/TextContent/TextContent')
);
const VideoTestimonials = dynamic(() =>
  import('@/components/ui/VideoTestimonials/VideoTestimonials')
);
const DametisProductCatalog = dynamic(() =>
  import('@/components/DametisProductCatalog/DametisProductCatalog')
);
const HowItWorks = dynamic(() => import('@/components/HowItWorks/HowItWorks'));
const PriceCatalogContainer = dynamic(() =>
  import('@/components/PriceCatalogContainer/PriceCatalogContainer')
);
const ReferenceProjectsList = dynamic(() =>
  import('@/components/ReferenceProjectsList/ReferenceProjectsList')
);
const ValueProposition = dynamic(() =>
  import('@/components/ValueProposition/ValueProposition')
);
const LoginForm = dynamic(() => import('@/components/LoginForm/LoginForm'));
const LoginHero = dynamic(() => import('@/components/LoginHero/LoginHero'));
const BentoGrid = dynamic(() => import('@/components/BentoGrid/BentoGrid'));
const BentoGridImages = dynamic(() =>
  import('@/components/BentoGridImages/BentoGridImages')
);

const renderFaq = faq => {
  const { headline, questionAndAnswers, id } = faq;

  const list = questionAndAnswers.map(qna => ({
    id: qna.id,
    question: qna.question,
    answer: <RichText data={qna.answer} />,
  }));

  return <Faq key={id} headline={headline} list={list} />;
};

const renderVideoTestimonials = testimonials => {
  const { id, cards, headline, subline } = testimonials;

  const testimonialCards = cards.map(card => {
    const {
      text: content,
      name: personName,
      companyName,
      image,
      video,
      bigImage,
      companyImage: logo,
    } = card;

    return {
      content,
      personName,
      companyName,
      ...(image?.data && { image: image.data }),
      ...(logo?.data && { logo: logo.data }),
      ...(video && { video }),
      ...(bigImage?.data && { bigImage: bigImage.data }),
    };
  });

  if (testimonialCards.some(card => card?.video || card?.bigImage)) {
    return (
      <PageMargins key={id}>
        <VideoTestimonials
          headline={headline}
          testimonials={testimonialCards}
        />
      </PageMargins>
    );
  }

  const testimonialCardsMapped = testimonialCards.map(
    ({ content, logo, image, personName, companyName }, index) => {
      return {
        id: index,
        title: null,
        text: stripHtmlTags(content),
        logo: { data: logo },
        image,
        customerName: personName,
        jobTitle: companyName,
      };
    }
  );

  return (
    <PageMargins key={id}>
      <Testimonials
        headline={stripHtmlTags(headline)}
        subline={subline}
        cards={testimonialCardsMapped}
      />
    </PageMargins>
  );
};

const renderTestimonials = testimonials => {
  const { id, chip, headline, mainText: subline, cards } = testimonials;

  return (
    <PageMargins key={id}>
      <Testimonials
        chip={chip}
        headline={headline}
        subline={subline}
        cards={cards}
      />
    </PageMargins>
  );
};

const renderProductCatalog = productCatalog => {
  const { name: headline, subline, tile: products } = productCatalog;

  return (
    <PageMargins>
      <ProductCatalog
        headline={headline}
        description={subline}
        products={products}
      />
    </PageMargins>
  );
};

const renderHero = hero => {
  const headline = hero.headline;
  const formType = hero.formType;
  const image = hero.image?.data;
  const variant = hero.variant;

  const headlineElement = (
    <RichText
      data={headline}
      includeEditorStyles={variant === 'category_page'}
    />
  );

  return (
    <Hero
      headline={headlineElement}
      formType={formType}
      pictures={image}
      variant={variant}
    />
  );
};

const renderPartners = partnerSet => {
  const partners = partnerSet?.partners?.data;
  const id = partnerSet?.id;
  const headline = partnerSet?.headline || null;

  if (!partners?.length) return null;

  return (
    <PageMargins key={id}>
      <PartnersSection logos={partners} headline={headline} />
    </PageMargins>
  );
};

const renderTextContent = textContent => {
  const id = textContent?.id;
  const headline = textContent?.headline;
  const columns = textContent?.columns || [];

  const headlineComponent = headline && (
    <RichText data={headline} includeEditorStyles />
  );
  const sectionComponents = columns.map((columnData, index) => {
    const content = columnData?.content;

    return (
      <RichText key={`${id}-${index}`} data={content} includeEditorStyles />
    );
  });

  return (
    <PageMargins sx={{ my: 6.25 }}>
      <TextContent headline={headlineComponent} sections={sectionComponents} />
    </PageMargins>
  );
};

const renderWideTextBlock = wideTextBlock => {
  const { id, content } = wideTextBlock;
  const section = [
    <RichText key={`${id}`} data={content} includeEditorStyles />,
  ];

  return (
    <PageMargins sx={{ my: 6.25 }}>
      <TextContent sections={section} wideTextBlock />
    </PageMargins>
  );
};

const renderPromises = id => (
  <PageMargins key={id}>
    <Promises />
  </PageMargins>
);

const renderFaqTeam = id => (
  <PageMargins key={id}>
    <FaqTeam />
  </PageMargins>
);

const renderBottomCTA = (id, heroVariant) => (
  <PageMargins sx={{ mt: 3, mb: 4 }}>
    <BottomCTA heroVariant={heroVariant} />
  </PageMargins>
);

const renderHomepageHero = id => <HomepageHero key={id} />;

const renderLoginForm = (id, refererPathname) => (
  <PageMargins key={id} style={{ margin: '32px auto', maxWidth: '750px' }}>
    <LoginForm refererPathname={refererPathname} />
  </PageMargins>
);

const renderReferenceProjectsList = pageProps => {
  const { referenceProjects, categories } = pageProps;

  if (referenceProjects) {
    const referenceProjectsMapped = referenceProjects.data.map(
      ({
        attributes: { title, excerpt, heroImage, metadata, referenceCategory },
      }) => {
        const categoryPathname =
          referenceCategory?.data?.attributes.metadata.pathname ||
          referenceProjectsConfig.allCategoryPathname;
        const slug = metadata?.pathname || '';
        const link = `${referenceProjectsConfig.basePathname}${categoryPathname}${slug}`;
        return {
          headline: title,
          image: heroImage,
          link,
          description: excerpt,
        };
      }
    );

    const referenceProjectsCategoriesMapped = categories.map(
      ({ attributes }) => ({
        label: attributes.title,
        value: attributes.metadata.pathname,
      })
    );

    const pagination = {
      page: referenceProjects.meta.pagination.page,
      pageCount: referenceProjects.meta.pagination.pageCount,
    };

    return (
      <ReferenceProjectsList
        referenceProjects={referenceProjectsMapped}
        pagination={pagination}
        categories={referenceProjectsCategoriesMapped}
      />
    );
  }
  return null;
};

const renderPlaceholder = (
  placeholder,
  heroVariant,
  pageProps,
  refererPathname
) => {
  const componentName = placeholder?.type;
  const id = placeholder?.id;

  if (componentName === 'promises') {
    return renderPromises(id);
  } else if (componentName === 'faq_team') {
    return renderFaqTeam(id);
  } else if (componentName === 'bottom_cta') {
    return renderBottomCTA(id, heroVariant);
  } else if (componentName === 'homepage_hero') {
    return renderHomepageHero(id);
  } else if (componentName === 'reference_projects_all') {
    return renderReferenceProjectsList(pageProps);
  } else if (componentName === 'login_form') {
    return renderLoginForm(id, refererPathname);
  }

  return null;
};

const renderDametisProductCatalog = (dametisProductCatalog, pathname) => {
  const productGroup = dametisProductCatalog.productGroup.data;
  const productGroupId = productGroup.attributes.blob.id;
  const productGroupName = productGroup.attributes.title;
  const productGroupDescription = dametisProductCatalog.description;
  const attributes = dametisProductCatalog.attributes.data;
  const requestType = dametisProductCatalog.requestType;

  return (
    <DametisProductCatalog
      pageId={pathname}
      productGroupId={productGroupId}
      productGroupName={productGroupName}
      attributes={attributes}
      productGroupDescription={productGroupDescription}
      requestType={requestType}
    />
  );
};

const renderTwoColumnGrid = component => (
  <PageMargins>
    <CardGrid
      cards={component.tiles}
      twoColumLayout
      centerImage={component.centerImage ?? true}
      headingVariant="h4"
    />
  </PageMargins>
);

const renderHowItWorks = component => {
  const { tag, headline, subline, description, link } = component;

  const steps = component.steps.map(({ headline, text, link, slides }) => ({
    headline,
    text,
    link: link.data?.attributes,
    slides:
      slides.data?.map(slide => ({
        image: {
          url: slide.attributes.url,
          alt: slide.attributes.alternativeText,
        },
      })) || [],
  }));

  const data = {
    tag,
    headline,
    subline,
    description,
    link: link.data?.attributes,
    steps,
  };

  return <HowItWorks data={data} />;
};

const renderValueProposition = component => {
  return <ValueProposition data={component} />;
};

const renderPriceCatalog = priceCatalog => {
  const products =
    priceCatalog.tile?.map(product => ({
      headline: product.name,
      image: product.image.data,
      price: product.price,
      initialPrice: product.initialPrice,
      priceBasis: product.priceBasis,
      attributes: product.attributes,
      url: product.url,
    })) || [];

  const dametisProducts =
    priceCatalog.dametisTile?.map(product => ({
      ...product,
      attributes: product?.attributes?.data?.map(
        attribute => attribute?.attributes?.blob?.name
      ),
    })) || [];

  return (
    <PageMargins style={{ marginBottom: '80px' }}>
      <PriceCatalogContainer
        products={products}
        dametisProducts={dametisProducts}
        requestType={priceCatalog.requestType}
      />
    </PageMargins>
  );
};

export const renderLoginHero = (loginHero, refererPathname) => {
  const { headline, image, chip, text, advantages } = loginHero;

  return (
    <LoginHero
      image={image}
      chip={chip}
      refererPathname={refererPathname}
      headline={headline}
      text={text}
      advantages={advantages}
    />
  );
};

export const renderBentoGridRow = bentoRow => {
  const { bentoBoxes } = bentoRow;
  return (
    <PageMargins sx={{ my: 6.25 }}>
      <BentoGrid boxes={bentoBoxes} />
    </PageMargins>
  );
};

export const renderBentoGridImageRow = bentoRow => {
  const { imageBentoBoxes } = bentoRow;
  return (
    <PageMargins sx={{ my: 6.25 }}>
      <BentoGridImages boxes={imageBentoBoxes} />
    </PageMargins>
  );
};

export default function Page({ page, refererPathname, pageProps }) {
  const components = page?.attributes.body;
  const pathname = page?.attributes?.metadata.pathname;

  const heroVariant = components?.find(
    ({ __component }) => __component === 'heroes.hero'
  )?.variant;

  return (
    <>
      {components?.map(component => {
        const { __component, id } = component;
        if (!component) {
          return null;
        }

        return (
          <Fragment key={`${__component}-${id}`}>
            {__component === 'heroes.hero' && renderHero(component)}
            {__component === 'shared.partners' && renderPartners(component)}
            {__component === 'text-content.text-content' &&
              renderTextContent(component)}
            {__component === 'text-content.wide-text-block' &&
              renderWideTextBlock(component)}
            {__component === 'shared.placeholder' &&
              renderPlaceholder(
                component,
                heroVariant,
                pageProps,
                refererPathname
              )}
            {__component === 'catalogs.product-catalog' &&
              renderProductCatalog(component)}
            {__component === 'testimonial-cards.testimonial-cards' &&
              renderVideoTestimonials(component)}
            {__component === 'testimonials.testimonial' &&
              renderTestimonials(component)}
            {__component === 'faq.frequently-asked-questions' &&
              renderFaq(component)}
            {__component === 'catalogs.dametis-product-catalog' &&
              renderDametisProductCatalog(component, pathname)}
            {__component === 'two-column-grid.two-column-grid' &&
              renderTwoColumnGrid(component)}
            {__component === 'how-it-works.how-it-works' &&
              renderHowItWorks(component)}
            {__component === 'catalogs.price-catalog' &&
              renderPriceCatalog(component)}
            {__component === 'value-propositions.value-propositions' &&
              renderValueProposition(component)}
            {__component === 'login.login-hero' &&
              renderLoginHero(component, refererPathname)}
            {__component === 'bento-boxes.bento-boxes-row' &&
              renderBentoGridRow(component)}
            {__component === 'bento-boxes.image-bento-boxes-row' &&
              renderBentoGridImageRow(component)}
          </Fragment>
        );
      })}
    </>
  );
}

Page.propTypes = {
  page: PropTypes.object.isRequired,
  refererPathname: PropTypes.string,
};
